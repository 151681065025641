import {wrappers} from "./modules/modulesCompiler";
import {Toaster} from "react-hot-toast";
import CookieConsent from "react-cookie-consent";

function App() {
    const wrap = (modules, index = 0) => {
        if(!modules[index])       {
            return null;
        }
        const Wrapper = modules[index];
        return <Wrapper>
            {wrap(modules,index+1)}
        </Wrapper>
    }
    return (
        <div>
            <Toaster />
            {wrap(wrappers)}
            <CookieConsent
                location="bottom"
                buttonText="Slažem se"
                declineButtonText="Odbijam"
                enableDeclineButton
                cookieName="myAppCookies"
                expires={150}
                style={{
                    background: "#e10e31",
                    color: "#f8fafc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "2rem",
                    paddingBottom: "3rem",
                    width: "100%",
                    bottom: "1rem",
                    position: "fixed",
                    zIndex: "1000",
                }}
                buttonStyle={{
                    background: "#f6f6f6",
                    color: "#404040",
                    fontSize: "1rem",
                    padding: "0.5rem 1.5rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                }}
                declineButtonStyle={{
                    background: "#ef4444",
                    color: "#fff",
                    fontSize: "1rem",
                    padding: "0.5rem 1.5rem",
                    borderRadius: "4px",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                <div >
        <span style={{ fontSize: "1rem" }}>
          Koristimo neophodne kolačiće kako bi naš sajt radio. Sa vašim pristankom, možemo takođe koristiti kolačiće koji nisu neophodni kako bismo poboljšali korisničko iskustvo, personalizovali sadržaj, prilagodili oglase i analizirali saobraćaj na sajtu. Zbog ovih razloga, možemo deliti vaše podatke o korišćenju sajta sa našim partnerima za društvene mreže, oglašavanje i analitiku. Klikom na "Prihvatam", pristajete na upotrebu kolačića na našem sajtu.
        </span>
                </div>
            </CookieConsent>
        </div>
    );
}

export default App;
