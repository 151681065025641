import React, {useState} from "react";
import {trans, widget} from "../../../modulesCompiler";
import ReCAPTCHA from "react-google-recaptcha";
import {Download, Loader, Send} from "react-feather";
import Map from "./Map";
import Map2 from "./Map2";
import {gql, useMutation} from "@apollo/client";
import toast from "react-hot-toast";
import GraphQLErrorField from "../../../../app/GraphQLErrorField";
import GraphQLErrorMessage from "../../../../app/GraphQLErrorMessage";

const CONTACT_FORM_QUERY = "contactForm";
const CONTACT_FORM = gql`
    mutation ($name: String!, $email: String!, $message: String!, $recaptcha: String!)  {
        contactForm(name: $name, email: $email, message: $message, recaptcha: $recaptcha) {
            error
            success
        }
    }
`


function Contact() {
    const defaultForm = {email: "", name: "", message: "", recaptcha: ""};
    const [form, setForm] = useState(defaultForm);
    const [contactForm, {error, loading}] = useMutation(CONTACT_FORM);

    const changeField = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        setForm({...form, [name]: value});
    };
    const onChangeRecaptcha = (key) => {
        setForm({...form, recaptcha: key});
    }

    const submit = (e) => {
        e.preventDefault();
        contactForm({variables: form}).then(response => {
            const data = response.data[CONTACT_FORM_QUERY];
            if(data.success)    {
                toast.success("Poslato!");
                setForm(defaultForm);
                return false;
            }
            if(data.error)  {
                toast.error(data.error);
            }

        }).catch(error => {

        });
    }


    return (
        <>
            {widget("public.Breadcrumbs", {links: [{trans: trans("kontakt")}]})}
            <div className="container mb-6">
                <div className="bg-white rounded-3 p-4 mb-5">
                    <div className="row gx-md-5">
                        <div className="col-md-6 mb-5 mb-md-0">
                            <h3 className="oswald fw-bold text-uppercase mb-4">kontaktirajte nas</h3>
                            <form className="mb-md-0 mb-3" action="#" method="post" onSubmit={submit}>
                                <div className="mb-3">
                                    <input onChange={changeField} value={form.name}
                                           type="text" name="name" className="form-control form-control-lg"
                                           placeholder="Ime i prezime" required/>
                                    <GraphQLErrorField error={error} field="name"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" onChange={changeField} value={form.email}
                                           name="email" className="form-control form-control-lg"
                                           placeholder="Email" required/>
                                    <GraphQLErrorField error={error} field="email"/>
                                </div>
                                <div className="mb-3">
                            <textarea onChange={changeField} value={form.message}
                                      name="message" className="form-control"
                                      placeholder="Poruka..."/>
                                    <GraphQLErrorField error={error} field="message"/>
                                </div>

                                <div className="mb-3">
                                    <ReCAPTCHA
                                        sitekey="6LcoAq8cAAAAACFPKJUCwy_hg1I8KUbaKUU2PLuc"
                                        onChange={onChangeRecaptcha}/>
                                    <GraphQLErrorField error={error} field="recaptcha"/>
                                </div>

                                <div className="">
                                    <button disabled={loading} className="btn ps-3 pe-3 btn-primary" type="submit">
                                        {loading ? <Loader className="rotate"/> : <Send/>} Pošaljite
                                    </button>
                                </div>
                                <GraphQLErrorMessage error={error}/>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="fw-bold text-uppercase mb-3">Adrese</h3>
                                    <div className="mb-3 lh-lg">
                                        Put za Šebeke 10, <br/>
                                        Konjevići, Čačak <br/>
                                        Pavla Vuisića 28,<br/>
                                        Altina, Zemun
                                    </div>
                                    <h3 className="fw-bold text-uppercase mb-3">Telefoni</h3>
                                    <div className="mb-3 lh-lg">
                                        <a href="tel:+381 032 375 412">+381 (0) 32375412</a> <br/>
                                        <a href="tel:+381 032 382 077">+381 (0) 32382077</a> <br/>
                                        <a href="tel:+381 063 697 777">+381 (0) 63697777</a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h3 className="fw-bold text-uppercase mb-3">E-mail</h3>
                                    <div className="mb-3 lh-lg">
                                        <a href="mailto:barok.dizajn@gmail.com">barok.dizajn@gmail.com</a>
                                    </div>
                                    <h3 className="fw-bold text-uppercase mb-3">Podaci</h3>
                                    <a href="/barok032-podaci-za-identifikaciju.pdf" target="_blank" rel="noreferrer">
                                        <Download/> Podaci za identifikaciju
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h3 className="fw-bold text-uppercase mb-3">Čačak</h3>
                <Map/>
                <h3 style={{marginTop: 40}} className="fw-bold text-uppercase mb-3">Beograd</h3>
                <Map2/>
            </div>
        </>
    )
}

export default Contact;