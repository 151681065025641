import React from "react";
import {trans, widget} from "../../../modulesCompiler";
import artikli from "../../../../assets/images/6500-artikala.png";
import deliveryTruck from "../../../../assets/images/delivery-truck.png";
import helping from "../../../../assets/images/helping.png";
import rating from "../../../../assets/images/rating.png";

function About() {


    return (
        <div className="container">
            {widget("public.Breadcrumbs", {links: [{trans: trans("o nama")}]})}

            <div className="bg-white rounded-3 p-4 mb-6">
                <span className="text-uppercase fw-bold text-secondary"> „Barok dizajn“ doo </span> se bavi uvozom i
                distribucijom kućne dekoracije, keramike, porcelana, dekorativnog
                stakla i stakla za kuću, kućnog tekstila, baštenskog programa, plastike za kuhinjsku upotrebu, proizvoda
                za ručavanje, školskog pribora i igračaka. Pored toga ima sopstvenu proizvodnju suvenira i satova i
                mogućnost štampanja reprezentativnog materijala.<br/><br/>

                Poslovanje kompanije „Barok dizajn“ počinje 1997. godine u okviru maloprodajne radnje u robnoj kući u
                Čačku pod imenom „Diznilend“. Kasnije, kako su se potrebe tržišta menjale, trgovinska radnja
                „Diznilend“ je preregistrovala svoju granu poslovanja i postala veleprodaja. Kako je obim posla rastao,
                2007. godine, kompanija je odlučila da promeni svoju strategiju, stoga je promenila i ime. Tako je
                nastalo
                društvo sa ograničenom odgovornošću „Barok dizajn“. <br/><br/>

                Iako je teritorija Srbije znatno pokrivena našim proizvodima, imamo jasan cilj da se i dalje širimo i
                razvijamo kako u Srbiji, tako i u zemljama regiona.<br/><br/>

                Nakon više od dve decenije uspešnog poslovanja, „Barok dizajn“ je od neprepoznatljive i nove firme na
                tržištu, postala jedna od vodećih veleprodaja u Srbiji u ovoj delatnosti.
                <br/><br/>
                Kvalitet našeg poslovanja oslikava činjenica da sarađujemo sa stalnim kupcima i inostranim dobavljačima
                više od 20 godina. Uprkos tome, uvek smo raspoloženi za saradnju sa novim kupcima.
                <br/><br/>
                Politika kuće je da uvek ima raznovrstan asortiman i potrebne količine na lageru.
                <br/><br/>
                Misija „Barok dizajna“ je da prepozna potrebe, osluškuje i ispuni želje kupca, uvažava i uzima uobzir
                mišljenja zaposlenih i da ima zdrav, neretko, partnerski odnos sa konkurentskim kompanijama.
                <br/><br/>
                Vizija „Barok dizajna“ da svojim proizvodima spoji tradicionalno i moderno, što znači da kod nas možete
                pronaći artikle po svačijem ukusu.
                <br/><br/>
                Usled rasta broja novih kupaca, nastala je potreba da se "Barok dizajn" proširi na još jednu lokaciju i to u Beogradu. U oktobru 2023 otvoren je izložbeni prostor na Altini.

                <div className="mt-5 mb-4">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-3 col-6 mb-3 mb-md-0 text-center">
                                    <div className="bg-secondary p-4 d-inline-block mb-2" style={{borderRadius: 30}}>
                                        <img src={artikli} className="img-fluid" width={50} alt="PREKO 6500 ARTIKALA"/>
                                    </div>
                                    <div className="fw-bold lh-sm">PREKO <br/> 6500 ARTIKALA</div>
                                </div>
                                <div className="col-md-3 col-6 mb-3 mb-md-0 text-center">
                                    <div className="bg-secondary p-4 d-inline-block mb-2" style={{borderRadius: 30}}>
                                        <img src={helping} className="img-fluid" width={50} alt="PREKO 6500 ARTIKALA"/>
                                    </div>
                                    <div className="fw-bold lh-sm">UVEK RASPOLOŽENI <br/> ZA SARADNJU</div>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <div className="bg-secondary p-4 d-inline-block mb-2" style={{borderRadius: 30}}>
                                        <img src={rating} className="img-fluid" width={50} alt="PREKO 6500 ARTIKALA"/>
                                    </div>
                                    <div className="fw-bold lh-sm">VISOKA STRUČNOST <br/> ZAPOSLENIH</div>
                                </div>
                                <div className="col-md-3 col-6 text-center">
                                    <div className="bg-secondary p-4 d-inline-block mb-2" style={{borderRadius: 30}}>
                                        <img src={deliveryTruck} className="img-fluid" width={50}
                                             alt="PREKO 6500 ARTIKALA"/>
                                    </div>
                                    <div className="fw-bold lh-sm">BRZA I EFIKASNA <br/> DOSTAVA</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About;